/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import floridaHero from '../../images/locationsHeroes/hero-florida.jpg'

import { StaticImage } from 'gatsby-plugin-image'
import DynamicCTACard from '../../components/DynamicCTACard'
import SeoCtaBar from '../../components/SeoCtaBar'

import CobraBanner from '../../components/CobraBanner'

import {
  Accordion,
  Columns,
  Column,
  Hero,
  List,
  ListItem,
  Stack,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/Footer'
import header from '../../components/Header'

const Home = ({ data }) => {
  const brandyList = [
    'disclaimer-thirdpartymarketing',
    'disclaimer-plan',
    'disclaimer-evidence',
    'dsiclaimer-changenotice',
    'disclaimer-aetnabrand',
    'disclaimer-clicopyright',
    'disclaimer-incompletedescrip',
    'disclaimer-cmsmaterialid',
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            'Apply for Aetna Medicare Plans in Florida | Call 833-998-1009 ',
          description:
            'Learn more about Aetna Medicare plans in Florida. Let us help you find the right plan and help answer any questions you might have. Call 833-998-1009',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/florida',
        promoCode: '120783',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Apply for Aetna Medicare Plans in Florida | Call 833-998-1009 ',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={floridaHero}
                  alt="elderly couple looks lovingly at one another while walking on beach"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1" color="light">
                    Aetna<sup>®</sup> Medicare, Florida{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>

        <SeoCtaBar />

        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                Aetna Medicare Insurance in Florida
              </Typography>
              <Typography variant="body">
                With all its sunshine and sandy beaches, everyone knows Florida
                is the go-to place for retirees. That’s probably why it’s the
                state with the second highest number of people receiving
                Medicare benefits with over{' '}
                <a
                  href="https://www.kff.org/medicare/state-indicator/total-medicare-beneficiaries/?currentTimeframe=0&selectedRows=%7B%22states%22:%7B%22all%22:%7B%7D%7D,%22wrapups%22:%7B%22united-states%22:%7B%7D%7D%7D&sortModel=%7B%22colId%22:%22Total%20Medicare%20Beneficiaries%22,%22sort%22:%22desc%22%7D"
                  target="_blank"
                >
                  over 4 million people enrolled
                </a>{' '}
                in Medicare as of 2018.
              </Typography>
              <StaticImage
                src="../../images/illustrations/florida-stats.svg"
                alt="over 4 million Floridians enrolled in Medicare plans"
                layout="fixed"
              />
              <Typography variant="body">
                If you’re looking for more information about Medicare plans in
                Florida, you’ve come to the right place. Speak with a licensed
                agent today to learn more about your options in the Sunshine
                State.
              </Typography>
            </>
          }
        ></VariableContent>
        <CobraBanner />
        <VariableContent backgroundColor="lightGray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-65.svg"
                alt="65 icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                Types of Medicare Plans in Florida
              </Typography>
              <Typography variant="body">
                There are a variety of Medicare plans to choose from in Florida
                and all other states. You may elect to get federally funded
                health coverage through Original Medicare, or you can opt for a{' '}
                <a href="/advantage">Medicare Advantage plan</a> offered through
                a Medicare-approved private insurance company.
              </Typography>
              <Typography variant="body">
                Your health coverage options may vary depending on where you
                live in the state, so speak with a licensed agent today to find
                out what kind of coverage you are eligible for.
              </Typography>
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion
                  items={[
                    {
                      title: 'Original Medicare (Parts A and B)',
                      content: (
                        <>
                          <Typography>
                            Original Medicare is a federally funded and
                            administered health insurance program made
                            especially for people 65 or older and for younger
                            folks with certain health conditions. It’s available
                            to every eligible Floridian. Part A covers inpatient
                            hospital, skilled nursing, and hospice care, while
                            Part B covers outpatient doctor’s services and
                            durable medical equipment (DME), such as
                            wheelchairs, walkers, insulin pumps, or oxygen
                            equipment..
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Medicare Advantage (Part C) plans',
                      content: (
                        <>
                          <Typography>
                            Medicare Advantage plans are offered by private
                            insurance companies and regulated by the federal
                            government. By law, all Medicare Advantage plans
                            must provide at least the same coverage as Original
                            Medicare. Most Medicare Advantage plans provide
                            additional benefits, too, such as vision, dental,
                            health and wellness programs, and gym memberships.
                            Depending on your coverage area, they may also offer
                            prescription drug (part D) coverage.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Medicare Part D (prescription drug) plans',
                      content: (
                        <>
                          <Typography>
                            Medicare Part D is an optional health insurance plan
                            you can purchase that helps cover the costs of
                            prescription drugs and medications. Depending on
                            where you live, you may be able to buy a Part D plan
                            separately or as part of a Medicare Advantage plan.
                          </Typography>
                        </>
                      ),
                    },
                  ]}
                />
              </Stack>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-heart.svg"
                alt="heart icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                Am I Eligible for a Florida Medicare Advantage Plan?
              </Typography>
              <Typography variant="body">
                In order to qualify for a Medicare Advantage plan, you must
                first have both Part A and Part B of Medicare (“Original”
                Medicare) and live in the plan’s service area. Typically, this
                means you need to be at least 65 years old, though some
                exceptions may apply.
              </Typography>
              <Typography variant="body">
                If you have any questions or concerns regarding your eligibility
                for Medicare or Aetna Medicare Advantage, give us a call. A
                friendly, licensed agent can help.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent backgroundColor="lightGray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-calendar.svg"
                alt="calendar icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">When Can I Enroll?</Typography>
              <Typography variant="body">
                If you’re enrolling in Medicare for the first time and you
                qualify for both Part A and Part B of Medicare, you can enroll
                in an Aetna Medicare Advantage plan during your{' '}
                <a href="/enrollment">Initial Enrollment Period</a>. Your
                Initial Enrollment Period begins three months before the month
                of your 65th birthday and ends three months after your birth
                month.
              </Typography>
              <Typography variant="body">
                If you’re already on Medicare and want to enroll in a Medicare
                Advantage plan, you can do so during the Annual Enrollment
                Period, which starts October 15 and ends December 7 every year.
              </Typography>
              <Typography variant="body">
                Under some circumstances, you may be able to enroll in an Aetna
                Medicare Advantage plan during a Special Enrollment Period. For
                example, if you’ve recently moved or if you’re currently living
                in a nursing home. Talk to a licensed agent today to see if your
                life circumstance applies.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-magnify.svg"
                alt="magnifying glass icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                Other Medicare Resources in Florida
              </Typography>
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion
                  items={[
                    {
                      title: 'Medicare Savings programs in Florida',
                      content: (
                        <>
                          <Typography>
                            If you live in Florida and your income is below a
                            certain limit, you may qualify for a{' '}
                            <a
                              href="https://www.medicare.gov/your-medicare-costs/help-paying-costs/medicare-savings-program/medicare-savings-programs.html"
                              target="_blank"
                            >
                              Medicare Savings program
                            </a>
                            , which can help pay for some of the costs
                            associated with coverage, such as deductibles or
                            copayments on prescription drugs. Some of these
                            programs include:
                          </Typography>
                          <List>
                            <ListItem>
                              Qualified Medicare Beneficiary (QMB) Program
                            </ListItem>
                            <ListItem>
                              Specified Low-Income Medicare Beneficiary (SLMB)
                              Program
                            </ListItem>
                            <ListItem>
                              Qualifying Individual (QI) Program
                            </ListItem>
                            <ListItem>
                              Qualified Disabled and Working Individuals (QDWI)
                              Program
                            </ListItem>
                          </List>
                        </>
                      ),
                    },
                    {
                      title: 'Florida Department of Elder Affairs',
                      content: (
                        <>
                          <Typography>
                            Florida is known for its large senior citizen and
                            retiree population. The{' '}
                            <a
                              href="http://elderaffairs.state.fl.us/index.php"
                              target="_blank"
                            >
                              Florida Department of Elder Affairs
                            </a>{' '}
                            provides Sunshine State residents like you with
                            health services and information (among other things)
                            that may prove very helpful, whether or not you’re
                            enrolled in Medicare. Some examples of these
                            services include the following:
                          </Typography>
                          <List>
                            <ListItem>
                              Elder Helpline information and assistance service
                            </ListItem>
                            <ListItem>Health and Wellness program</ListItem>
                            <ListItem>
                              Community Care for the Elderly program
                            </ListItem>
                            <ListItem>Alzheimer’s Disease Initiative</ListItem>
                          </List>
                        </>
                      ),
                    },
                    {
                      title: 'Serving Health Insurance Needs of Elders (SHINE)',
                      content: (
                        <>
                          <Typography>
                            SHINE helps Florida seniors make smart decisions
                            about their health care by organizing health-related
                            events in communities throughout the Sunshine State.
                            They also provide on-site counseling centers in all
                            67 of Florida’s counties. All these services are
                            offered at no cost to you and are available to all
                            Florida Medicare recipients.
                          </Typography>
                        </>
                      ),
                    },
                  ]}
                />
              </Stack>
            </Column>
          </Columns>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
